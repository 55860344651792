export class User {
    id!: string;
    firstName!: string;
    lastName!: string;
    username!: string;
    email!: string;
    dateOfBirth!: string;
    phoneNumber!: string;
    password!: string;
    recommendedBy!: string;
    roles!: string[];
    createdDate!: Date;
    enabled!: boolean;
    verified!: boolean;
}