import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user!: User | null;
    private _token!: string | null;

    private httpHeaders = new HttpHeaders({
        'Content-Type':'application/x-www-form-urlencoded',
        'Authorization':'Basic YW5ndWxhcmFwcDoxMjM0NQ=='
    });

    constructor(private http: HttpClient, private router:Router, private jwtHelper: JwtHelperService) { }

    public get user() {
        if (this._user != null) {
            return this._user;
        } else if (sessionStorage.getItem('user') !== null) {
            this._user = JSON.parse(sessionStorage.getItem('user') as string);
            return this._user;
        } else {
            return new User();
        }
    }

    public get token() {
        if(this._token != null) {
            return this._token;
        } else if (sessionStorage.getItem('user') !== null) {
            this._token = sessionStorage.getItem('token');
            return this._token;
        } else {
            return null;
        }
    }

    login(email:string, password:string): any {
        let params = new URLSearchParams();
        params.set('grant_type', 'password');
        params.set('username', email);
        params.set('password', password);
        return this.http.post<any>(environment.apiUrl + "oauth/token", params.toString(), { headers :this.httpHeaders });
    }

    logout() {
        this._user = null;
        this._token = null;
        sessionStorage.clear();
        this.router.navigate(['/auth']);
    }

    isAuthenticated() {
        const token: any = sessionStorage.getItem('token');
        return !this.jwtHelper.isTokenExpired(token);
    }

    storeUser(user:User) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    storeToken(token:string) {
        sessionStorage.setItem('token', token);
    }
}
