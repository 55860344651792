import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        if(this.authService.isAuthenticated()) {
            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${ this.authService.token }`
                }
            });
        } else {
            console.log('is not authenticated, clearing cache');
            sessionStorage.clear();
        }
        return next.handle(request);
    }
}