import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

  token!: string;
  decodedToken!: { [key: string]: any };
  user: User = new User();

  constructor() {}

  setToken(token: string) { if(token) { this.token = token; } }

  decodeToken() { this.decodedToken = this.token ? jwt_decode(this.token) : jwt_decode(sessionStorage.token); }

  getDecodedToken() { return jwt_decode(this.token); }

  getUser() {
      this.decodeToken();
      if (this.decodedToken) {
        this.user = JSON.parse(this.decodedToken.user);
      }
      return this.user;
  }
}
